import custom from '../../lang/ru/custom.json'
import { models } from '../../lang/ru/models.json'
import { breadcrumbs } from '../../lang/ru/breadcrumbs.json'
import { fsm } from '../../lang/ru/fsm.json'
import { views } from '../../lang/ru/views.json'
import { layouts } from '../../lang/ru/layouts.json'
import { flash } from '../../lang/ru/flash.json'

export const defaultNS = 'custom'
export const resources = {
  ru: {
    custom,
    models,
    breadcrumbs,
    fsm,
    views,
    layouts,
    flash,
  },
}
