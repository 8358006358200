import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import _ from 'lodash'

import config from '../config'
import { resources, defaultNS } from '../i18resources'
import { FSMFieldName, FSMModelName, FSMStateOrEventsName, FSMTypeName, ModelName, ModelsProperty, ModelsPropertyWithBase } from '@/types'

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS,
    ns: Object.keys(resources.ru),
    resources,
    lng: config.lang, // if you're using a language detector, do not define the lng option
    fallbackLng: 'ru',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export function getPluralForm(n: number, lng: 'ru' | 'en' = 'ru'): 'few' | 'one' | 'many' {
  if (lng === 'ru') {
    if (n % 10 === 1 && n % 100 !== 11) return 'one'
    if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 12 || n % 100 > 14)) return 'few'
    return 'many'
  }
  return n === 1 ? 'one' : 'many'
}

export function tModel<
  M extends ModelName,
>(modelName: M, property?: ModelsPropertyWithBase<M>): string {
  if (!property) {
    const modelNamePath = `models:${modelName}.name` as const
    if (!i18n.exists(modelNamePath)) {
      console.log(`Translation key ${modelNamePath} does not exist`)
      return ''
    }
    return i18n.t(modelNamePath, { defaultValue: '' })
  }
  const path = `models:${modelName}.attributes.${property}` as const
  // @ts-expect-error https://www.i18next.com/overview/typescript#type-error-template-literal
  return i18n.exists(path) ? i18n.t(path) : i18n.t(`models:base.attributes.${property}`)
}

export function tFSM<
  M extends FSMModelName,
  F extends FSMFieldName<M>,
  T extends FSMTypeName<M, F>,
  V extends FSMStateOrEventsName<M, F, T>,
>(modelName: M, fieldName: F, typeName: T, stateOrEventName: V): string {
  return i18n.t(`fsm:${modelName}.${fieldName}.${typeName}.${stateOrEventName}`, { defaultValue: '' })
}

export function useViewTranslation(scope: string, ns: keyof (typeof resources['ru']) = 'views') {
  const { t } = useTranslation(ns)
  const tViews = (key: string, placeholders?: Record<string, string | number | undefined>): string => {
    if (!key) {
      console.log(`Translation key '${key}' is not found`)
      return ''
    }
    const path = `${scope}${key}`
    // @ts-expect-error Надо разобраться с типами
    return t(path, placeholders) || ''
  }

  return { t: tViews }
}

export default i18n
